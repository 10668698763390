import React from "react";
import { graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01";
import ModalSimple from "@solid-ui-blocks/Modal/Block02";
import Header from "@solid-ui-blocks/Header/Block01";
import Content from "@solid-ui-blocks/Content/Block02";
import Gallery from "@solid-ui-blocks/Blog/Block01";
import Contact from "@solid-ui-blocks/CallToAction/Block02";
import Team from "@solid-ui-blocks/Hero/Block03";
import Footer from "@solid-ui-blocks/Footer/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import styles from "./_styles";
import { Link as GLink } from "gatsby";

const Services03 = (props) => {
  const { allBlockContent } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  return (
    <Layout {...props}>
      <Seo title="About" />
      {/* Modals */}
      {/* <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} /> */}
      {/* Blocks */}
      {/* <Header content={content['header-light']} /> */}
      {/* <Divider space='4' /> */}
      {/* <Container variant='wide' sx={styles.heroContainer}>
        <Content content={content['hero']} />
        <Divider space='3' />
        <Gallery content={content['gallery']} />
      </Container> */}
      {/* <Divider space='5' /> */}
      {/* <Container variant='wide' sx={styles.teamContainer}>
        <Team content={content['team']} />
      </Container> */}
      <Content content={content["services-back"]} />
      <Divider space="2" />
      <Container variant="wide">
        <Content content={content["services-intro"]} />
        <Content content={content["services-distributions"]} />
        <Content content={content["services-quick-install"]} />
        <Divider space="3" />
        <Content content={content["services-manual-install"]} />
        {/* <Content content={content['services-browse']} />
      <GLink to='https://archive.swiftlang.xyz/repos/'>
      <Content content={content['services-browse-link']} />
      </GLink> */}
        {/* <Divider space='3' /> */}
        {/* <Content content={content["services-code"]} /> */}
        <GLink to="https://packagecloud.io/swift-arm/release/install#manual-deb">
          <Content content={content["services-code-link"]} />
        </GLink>
        <Content content={content["services-help"]} />
        <GLink to="https://forums.swift.org/t/new-swift-package-repository-for-ubuntu-debian-linux-distributions/50412">
          <Content content={content["services-help-link"]} />
        </GLink>
        <Content content={content["services-donate"]} />
        <GLink to="https://ko-fi.com/futurejones#">
          <Content content={content["services-donate-link"]} />
        </GLink>
        <hr />
      </Container>
      <Divider space="3" />
    </Layout>
  );
};

export const query = graphql`
  query innerpageSiteServices03BlockContent {
    allBlockContent(filter: { page: { in: ["site/user-guide", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

export default Services03;
